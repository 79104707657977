import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { LatLng } from "leaflet";
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
// import helpmeImage from "../images/helpme.png";
import nohelpImage from "../images/nohelp.png";
import { DAMAGES_PHOTO_DIR } from "../config/endpoint";
import "leaflet/dist/leaflet.css";

const DamageReportMapContent = ({ globalState }) => {
  const {
    officeName,
    damageReports,
  } = globalState.state;
  const mapRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const position = new LatLng(35.33607, 136.70769);
  const selectedPos = (() => { return selectedMarker ? new LatLng(selectedMarker.location.latitude, selectedMarker.location.longitude) : null })();

  // const [mapSize, setMapSize] = useState(
  //   { w: document.documentElement.clientWidth * 0.7, h: document.documentElement.clientHeight * 0.7 });
  // const mapSize = { w: document.documentElement.clientWidth * 0.7, h: document.documentElement.clientHeight * 0.7 }

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getDamageReports(params);
  }

  useEffect(() => {
    loadFunc();
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      const bounds = damageReports.map(marker => new LatLng(marker.location.latitude, marker.location.longitude));
      if (bounds && bounds.length && bounds.length !== 0) {
        map.invalidateSize();
        map.fitBounds(bounds);
      }

    }
  }, [mapRef, damageReports]);

  const renderDamageReportMakers = damageReports.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1 })
    .map((marker, i) => {
      const pos = marker.location ? new LatLng(marker.location.latitude, marker.location.longitude) : false;
      const icon = L.icon({
        iconUrl: nohelpImage, iconSize: [25, 41],
        iconAnchor: [12, 41]
      });
      if (pos) {
        return (
          <Marker position={{ lat: marker.location.latitude, lng: marker.location.longitude }} key={`marker${i}`} icon={icon} onClick={() => setSelectedMarker(marker)}>
            <Popup>
              <div>{marker.reportContent}</div>
              <div>{`${marker.userName} ${moment(marker.createdAt).format("MM/DD HH:mm")}`}</div>
              {marker.imageName ?
                <img src={`${DAMAGES_PHOTO_DIR}${marker.imageName}`} alt="image_name" style={{ maxWidth: '200px' }} /> : <></>
              }
            </Popup>
          </Marker>
        )
      } else {
        return <></>;
      }
    })
  const renderDamageReportList = damageReports.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1 })
    .map((marker, i) => {
      const pos = marker.location ? new LatLng(marker.location.latitude, marker.location.longitude) : false;
      return (
        <div
          key={`com${i}`}
          onClick={() => { if (pos) { setSelectedMarker(marker) } }}
          style={{
            marginTop: "10px",
            backgroundColor: "#ddd",
            borderRadius: "5px",
            padding: "10px"
          }}
        >
          <div>{marker.reportContent}</div>
          <div>{`${marker.userName} ${moment(marker.createdAt).format("MM/DD HH:mm")}`}</div>
          {marker.imageName ?
            <img src={`${DAMAGES_PHOTO_DIR}${marker.imageName}`} alt="image_name" style={{ maxWidth: '100px' }} /> : <></>
          }
        </div>
      )
    })

  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={10}>
          <MapContainer center={position} zoom={10}
            style={{ width: "85%", height: "600px" }}
            ref={mapRef}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {renderDamageReportMakers}
            {selectedMarker && (
              <Popup position={selectedPos} onClose={() => setSelectedMarker(null)} >
                <div>
                  <div>{selectedMarker.reportContent}</div>
                  <div>{`${selectedMarker.userName} ${moment(selectedMarker.createdAt).format("MM/DD HH:mm")}`}</div>
                  {selectedMarker.imageName ?
                    <img src={`${DAMAGES_PHOTO_DIR}${selectedMarker.imageName}`} alt="image_name" style={{ maxWidth: '150px' }} /> : <></>
                  }
                </div>
              </Popup>
            )}
          </MapContainer>
        </Col>
        <Col md={2}>
          <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
            {renderDamageReportList}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
const DamageReportMap = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <DamageReportMapContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default DamageReportMap;