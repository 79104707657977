import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Modal, Button, Form } from 'react-bootstrap';

const AddManualModal = (props) => {
    const { mdlShow, mdlSetHide, saveData } = props;
    const [isFile, setIsFile] = useState(false);
    const [title, setTitle] = useState("");
    const [file, setFile] = useState({});

    function FileDropzone() {
        const onDrop = useCallback(acceptedFiles => {
            if (acceptedFiles[0].type === "application/pdf") {
                // setTitle(acceptedFiles[0].name.split('.')[0]);
                setIsFile(true);
                setFile(acceptedFiles[0]);
            } else {
                alert("ファイルタイプが、PDFでありません。");
                return false;
            }
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
        const cln = isDragActive ? 'dropzone--isActive' : '';
        return (
            <div {...getRootProps()} className={`dropzone ${cln}`}>
                <input {...getInputProps()} />
            </div>
        )
    }

    const renderForm = <Form.Control
        type="text"
        size="sm"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder='マニュアル名を入力してください'
    />

    return (
        <Modal show={mdlShow} onHide={mdlSetHide}>
            <Modal.Header closeButton>
                マニュアルの追加
            </Modal.Header>
            <Modal.Body>
                {
                    isFile ? renderForm :
                        <>
                            <FileDropzone />
                            <p>下のボックスにマニュアルファイルをドロップするか、<br />クリックして、マニュアルファイルを選択してください。</p>
                        </>
                }
            </Modal.Body>
            <Modal.Footer>
                {isFile ? <Button variant="primary" onClick={() => { saveData(file, title); setIsFile(false) }}>
                    登　録
                </Button> : <></>}
                <Button variant="secondary" style={{ marginLeft: "8px" }} onClick={() => { setIsFile(false); mdlSetHide() }}>
                    キャンセル
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddManualModal;