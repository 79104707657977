import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";



const EditManualSearchTabModal = (props) => {
  const { mdlShow, mdlSetHide, saveData, tabList } =
    props;
  const [tabIds, setTabIds] = useState([]);

  useEffect(() => {
    const _tabIds = props.tabIds.map(id => Number(id));
    setTabIds(_tabIds);
  }, [props.tabIds]);
  const handleSaveData = () => {
    const params = {
      menu: 'tab',
      tabIds: tabIds,
    }
    saveData(params);
    mdlSetHide();
  }
  const handleSelectTab = (id) => {
    const index = tabIds.indexOf(id);
    if (index === -1) {
      setTabIds([...tabIds, id]);
    } else {
      const _tabIds = [...tabIds];
      _tabIds.splice(index, 1)
      setTabIds(_tabIds);
    }
  }

  const renderTabList = useMemo(() => {
    if (!Object.keys(tabList)) return "";
    return Object.keys(tabList).map(id => <Form.Check key={`t${id}`} label={tabList[id].name} type="checkbox" checked={tabIds.indexOf(Number(id)) !== -1 ? true : false} onChange={() => handleSelectTab(Number(id))} />);
  }, [tabIds])
  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>マニュアル検索タブの編集</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>検索タブ</div>
        <div >
          {renderTabList}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => handleSaveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditManualSearchTabModal;
