import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Row, Col } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
// import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { LatLng } from "leaflet";

import helpmeImage from "../images/helpme.png";
import nohelpImage from "../images/nohelp.png";
import moment from "moment";
import { DAMAGES_PHOTO_DIR } from "../config/endpoint";
import "leaflet/dist/leaflet.css";
import { Border } from "react-bootstrap-icons";

const DamageAnnounceMapContent = ({ globalState }) => {
  const {
    damageAnnounces,
  } = globalState.state;
  const mapRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  // const damageStateTexts = ["大丈夫です。被災していません。", "被災しています。助けてください。"];
  const position = new LatLng(35.33607, 136.70769);
  const selectedPos = (() => { return selectedMarker ? new LatLng(selectedMarker.location.latitude, selectedMarker.location.longitude) : null })();
  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getDamageAnnounces(params);
  }
  const imagesContext = require.context('../images/makers', false, /\.png$/);
  const importAllImages = (context) => {
    const images = {};
    context.keys().forEach((key, index) => {
      console.log(key)
      images[index + 1] = context(key);
    });
    return images;
  };
  const images = importAllImages(imagesContext);
  useEffect(() => {
    loadFunc();
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      const bounds = damageAnnounces.map(marker => new LatLng(marker.location.latitude, marker.location.longitude));
      if (bounds && bounds.length && bounds.length !== 0) {
        map.invalidateSize();
        map.fitBounds(bounds);
      }
    }
  }, [mapRef, damageAnnounces]);

  const renderDamageAnnounceMakers = damageAnnounces.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1 })
    .map((marker, i) => {
      const pos = new LatLng(marker.location.latitude, marker.location.longitude);
      let icon;
      if (images[Number(i) + 1]) {
        icon = L.icon({
          iconUrl: images[Number(i) + 1], iconSize: [31, 41],
          iconAnchor: [12, 41]
        });
      } else {
        icon = L.icon({
          iconUrl: nohelpImage, iconSize: [25, 41],
          iconAnchor: [12, 41]
        });
      }
      if (pos) {
        return (
          <Marker position={{ lat: marker.location.latitude, lng: marker.location.longitude }} key={`marker${i}`} icon={icon} onClick={() => setSelectedMarker(marker)}>
            <Popup>
              <div>{marker.officeName}</div>
              <div>{marker.reportContent}</div>
              <div>{`${marker.userName} ${moment(marker.createdAt).format("MM/DD HH:mm")}`}</div>
              {marker.imageName ?
                <img src={`${DAMAGES_PHOTO_DIR}${marker.imageName}`} alt="image_name" style={{ maxWidth: '200px' }} /> : <></>
              }
            </Popup>
          </Marker>
        )
      } else {
        return <></>;
      }
    })
  const makerColors = [
    "#FF0000",
    "#0000FF",
    "#00FF00",
    "#FFFF00",
    "#FFA500",
    "#800080",
    "#FFC0CB",
    "#A52A2A",
    "#00FFFF"
  ]
  const makerNoStyle = {
    display: "block",
    position: "absolute",
    top: "-10px"
    , left: "-10px"
    , fontSize: "16px"
    , border: "2px"
    , width: "20px"
    , height: "20px"
    , color: "#000"
    , zIndex: "100"
    , textAlign: "center"
    , lineHeight: "20px"
    , borderRadius: "10px"
    , backgroundColor: "#000"
    , color: "#FFF"
  }

  const renderDamageAnnounceList = damageAnnounces.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1 })
    .map((marker, i) => {
      const pos = marker.location ? new LatLng(marker.location.latitude, marker.location.longitude) : false;
      const makerColor = makerColors[(i - i % 10)] || "#000";
      return (
        <div
          key={`com${i}`}
          onClick={() => { if (pos) { setSelectedMarker(marker) } }}
          style={{
            position: "relative",
            marginTop: "15px",
            marginLeft: "10px",
            backgroundColor: "#ddd",
            borderRadius: "5px",
            padding: "10px"

          }}
        >
          <div style={{ ...makerNoStyle, backgroundColor: makerColor }}>{i + 1}</div>
          <div>{marker.officeName}</div>
          <div>{marker.reportContent}</div>
          <div>{`${marker.userName} ${moment(marker.createdAt).format("MM/DD HH:mm")}`}</div>
          {marker.imageName ?
            <img src={`${DAMAGES_PHOTO_DIR}${marker.imageName}`} alt="image_name" style={{ maxWidth: '100px' }} /> : <></>
          }
        </div>
      )
    })

  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <Row style={{ marginTop: "50px" }}>
        <Col md={10}>
          <MapContainer center={position} zoom={13}
            style={{ width: "85%", height: "600px" }}
            ref={mapRef}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {renderDamageAnnounceMakers}
            {selectedMarker && (
              <Popup position={selectedPos} onClose={() => setSelectedMarker(null)} >
                <div>
                  <div>{selectedMarker.officeName}</div>
                  <div>{selectedMarker.reportContent}</div>
                  <div>{`${selectedMarker.userName} ${moment(selectedMarker.createdAt).format("MM/DD HH:mm")}`}</div>
                  {selectedMarker.imageName ?
                    <img src={`${DAMAGES_PHOTO_DIR}${selectedMarker.imageName}`} alt="image_name" style={{ maxWidth: '150px' }} /> : <></>
                  }
                </div>
              </Popup>
            )}
          </MapContainer>
        </Col>
        <Col md={2}>
          <div style={{ maxHeight: "600px", overflowY: "scroll", width: "100%" }}>
            {renderDamageAnnounceList}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
const DamageAnnounceMap = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <DamageAnnounceMapContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default DamageAnnounceMap;