import React from "react";
import { useNavigate } from "react-router-dom";
const PrivacyPolycy = () => {
    const navigate = useNavigate();
    return (
        <div style={{ marginLeft: "10%", marginRight: "5%", marginTop: "30px" }}>
            <img
                alt=""
                src="./AppIcon.png"
                width="40"
                height="40"
                style={{ display: "inline-block" }}
                onClick={() => navigate('/')}
            />
            <span style={{ fontSize: "20px", marginLeft: "10px" }}>プライバシーポリシー個人情報保護方針</span>

            <div style={{ marginTop: "20px" }}>
                <p>社会福祉連携推進法人　黎明（以下、当法人）は、個人情報の重要性を認識し、以下の通りお客様の個人情報の適切な保護に努めます。（個人情報とは、保護法に規定する、生存する個人に関する情報で、特定の個人を識別すできるものを言います。）
                    <br />本ウェブサイト及びアプリケーション上で提供するサービスについて、以下の通りプライバシーポリシーを定めます。</p>
                <p>1.個人情報の取得について<br />
                    当法人は、お客様の個人情報の取得にあたって、本人又は第三者の権利利益を害するおそれがある場合などを除き、本人に対して利用目的を明らかにし、同意を頂いた上で取得致します。取得した個人情報はその目的以外に利用せず、権威範囲を限定し、適切に取り扱います。
                    <br />またアプリケーションから個人情報を自動的に取得することはございません。</p>
                <p>2.個人情報の利用について
                    <br />当法人は、個人情報の利用目的の必要な範囲内で利用致します。</p>
                <p>3.個人情報の提供につおいて
                    <br />当法人は、取得した個人情報は、法令に基づく命令などを除き、あらかじめお客様の同意を得ることなく第三者に提供することは以下の場合を除き、原則致しません。
                    <br />　　1)　法令に基づく場合
                    <br />　　2)　人の生命、身体又は財産の保護のため
                    <br />　　3)　国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して、協力する必要がある場合</p>
                <p>4.個人情報の安全管理について
                    <br />当法人は、取り扱う個人情報の漏洩、破壊、紛失、改ざんの防止その他個人情報の安全管理のために必要かつ適切な処置を講じます。</p>
                <p>5.個人情報の委託について
                    <br />当法人は、個人情報の取り扱いの全部又は一部を第三者に委託する場合は、当該第三者について厳正な調査を行い、取扱を委託された個人情報の安全管理が図られるよう、当該第三者に対する適切な監督を行います。</p>
                <p>6.個人情報の開示・訂正等について
                    <br />当法人は、本人から自己の個人情報についての開示・訂正・追加・削除の請求がある場合、適切な方法で速やかに請求に応じます。その際、本人であることが確認できない場合、請求には応じません。<br />当社の個人情報の取り扱いにつきまして、請求・お問合せ等ございましたら、下記までご連絡くださいますようお願い申し上げます。</p>
                <p>社会福祉連携推進法人　黎明　info@reimei-x.link</p>

            </div>
        </div>
    );
};

export default PrivacyPolycy;