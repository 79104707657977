import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";

import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { PlusLg, PencilFill } from 'react-bootstrap-icons';
import AddProductModal from "./modals/AddProductModal";

const ProductListContent = ({ globalState }) => {
  const {
    productList,
    isDataLoading,
  } = globalState.state;
  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const initData = {
    name: "",
    price: 0,
    pcs: 0,
    barcode: "",
  };
  const [data, setData] = useState(initData);
  const [mdlShow, setMdlShow] = useState(false);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    if (!admin || Number(admin) === 0) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");

    const params = { appId: appId, appPass: appPass };
    globalState.getProductInfo(params);

  }
  useEffect(() => {
    loadFunc();
  }, []);

  const handleEditProduct = (v) => {
    setData(v);
    setMdlShow(true);
  }

  const saveData = () => {
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");

    const params = { appId: appId, appPass: appPass, ...data, admin: 1 };
    globalState.addProduct(params).then(rs => {
      if (rs) {
        alert('登録しました。');
      } else {
        alert('登録できませんでした。');
      }
    });;
  }

  const handleDeleteProduct = (id) => {
    if (!window.confirm("この商品を削除してもよろしいですか？")) return false;
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, id: id };
    globalState.deleteProduct(params).then(rs => {
      if (rs) {
        alert('削除しました。');
      } else {
        alert('削除できませんでした。');
      }
    });;
  }


  const handleSetData = (key, value) => {
    let sd = { ...data };
    sd[key] = value;
    setData(sd);
  }

  const renderProductList = (() => {
    const list = [];
    productList.forEach(v => {
      list.push(
        <tr key={`pr${v.id}`}>
          <td>{v.name}</td>
          <td>{v.price}</td>
          <td>{v.pcs}</td>
          <td>{v.barcode}</td>
          <td>
            <Button variant="danger" size="sm" onClick={() => handleDeleteProduct(v.id)} >
              削除
            </Button>
          </td>
          <td>
            <Button variant="primary" size="sm" onClick={() => handleEditProduct(v)}>
              <PencilFill size={24} color="#FFF" />
            </Button>
          </td>
        </tr>
      )
    })
    return list;
  })();
  return (
    <Container fluid >
      <AdminNav />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 9, offset: 1 }}>
          <h5>
            本部備蓄品リスト
            <Button variant="primary" size="sm" style={{ marginLeft: "20px" }} onClick={() => handleEditProduct(initData)}>
              <PlusLg size={24} color="#FFF" />
            </Button>
          </h5>
          <div style={{ overflowY: "scroll", maxHeight: "600px" }}>
            <Table>
              <thead>
                <tr><th>品名</th><th>価格</th><th>入数</th><th>バーコード</th><th></th><th></th></tr>
              </thead>
              <tbody>
                {renderProductList}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <AddProductModal
        saveData={saveData}
        setData={handleSetData}
        data={data}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
      />
    </Container>
  )
}
const ProductList = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <ProductListContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default ProductList;