import React, { useMemo } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";



const AddOfficeModal = (props) => {
  const { mdlShow, mdlSetHide, setData, data, saveData, prefNames, cityNames, admin } =
    props;

  const handleSaveData = () => {
    if (data.prefecture_code === "" || data.city_code === "" || data.name === "") {
      alert("必要な項目が記入または選択されていません");
      return false;
    }
    if (Number(admin) === 0 && (data.email === "" || data.manager_name === "")) {
      alert("必要な項目が記入または選択されていません");
      return false;
    }
    saveData();
  }
  const renderPrefecture = useMemo(() => {
    const list = [];
    list.push(<option key="p0" value=""></option>);
    Object.keys(prefNames).forEach(id => {
      const v = String(id).length === 1 ? `0${id}` : id;
      list.push(<option key={`p${id}`} value={v}>{prefNames[id]}</option>);
    })
    return list;
  }, [prefNames]);
  const renderCity = useMemo(() => {
    const list = [];
    list.push(<option key="c0" value=""></option>);
    Object.keys(cityNames).forEach(id => {
      const v = String(id).length === 5 ? `0${id}` : id;
      if (String(v).substring(0, 2) === data.prefecture) {
        list.push(<option key={`c${id}`} value={v}>{cityNames[id]}</option>);
      }
    })
    return list;
  }, [cityNames, data.prefecture]);
  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>新規事業所登録</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="table" size="sm">
          <tbody>
            <tr>
              <th>事業所名</th>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={data.officeName}
                  onChange={(e) => setData("officeName", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>都道府県名</th>
              <td>
                <Form.Select
                  as="select"
                  size="sm"
                  value={data.prefecture}
                  onChange={(e) => setData("prefecture", e.target.value)}
                >
                  {renderPrefecture}
                </Form.Select>
              </td>
            </tr>
            <tr>
              <th>市町村名</th>
              <td>
                <Form.Select
                  as="select"
                  size="sm"
                  value={data.city}
                  onChange={(e) => setData("city", e.target.value)}
                >
                  {renderCity}
                </Form.Select>
              </td>
            </tr>
            {Number(admin) === 1 ?
              <tr>
                <th>料金</th>
                <td>
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      checked={data.paid === 1 ? "checked" : ""}
                      onClick={() => setData("paid", data.paid === 0 ? 1 : 0)} />
                    <Form.Check.Label>有料</Form.Check.Label>
                  </Form.Check>
                </td>
              </tr> : <></>}
            {Number(admin) === 0 ?
              <tr>
                <th>担当者名</th>
                <td>
                  <Form.Control
                    type="text"
                    size="sm"
                    value={data.manager}
                    onChange={(e) => setData("manager", e.target.value)}
                  />
                </td>
              </tr> : <></>}
            {Number(admin) === 0 ?
              <tr>
                <th>メールアドレス</th>
                <td>
                  <Form.Control
                    type="email"
                    size="sm"
                    value={data.email}
                    onChange={(e) => setData("email", e.target.value)}
                  />
                  こちらのメールアドレス宛に、App ID と メールアドレスを送信いたします。
                </td>
              </tr> : <></>
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => handleSaveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOfficeModal;
