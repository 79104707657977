import { Container } from "unstated";
import axios from "axios";
import { ENDPOINT } from '../config/endpoint';


export default class DataContainer extends Container {
  constructor(props = {}) {
    super();
    this.state = {
      isDataLoading: false,
      hazardMapLinks: [],
      evacuationAreaLinks: [],
      trainingData: false,
      stockpileList: [],
      manualList: [],
      bannerList: [],
      trainingList: [],
      productList: [],
      officeId: "",
      officeName: "",
      prefectureCode: "",
      cityCode: "",
      email: "",
      lineId: "",
      managerName: "",
      prefNames: {},
      cityNames: {},
      admin: 0,
      viewOfficeId: 0,
      officeList: {},
      damageAnnounces: [],
      diasterList: {},
      positionList: {},
      todoList: {},
      lineUserList: {},
      safetyConfirmationList: {},
      alertState: 0,
      inspectionCategoryList: {},
      inspectionSubCategoryList: {},
      inspectionItemList: {},
      inspectionRecords: {},
      manualDirectryList: {},
      manualSubDirectryList: {},
      manualSearchTabList: {},
      invisibleItemIds: [],
      trainingStateList: {},
      damageReports: [],
    };
  }

  getState() {
    return this.state;
  }
  async officeLogin(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'OfficeLogin', params)
      .then((rs) => {
        if (rs.data) {
          this.setState(
            {
              ...this.state,
              ...rs.data,
              isDataLoading: false
            }
          );
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getHomeInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'HomeInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getStockpileInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'StockpileInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getBookmarkInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'BookmarkInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getManualInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'ManualInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getTrainingInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'TrainingInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getSafeConfirmationInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'SafetyConfirmationInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async putSafetyConfirmation(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'PutSafetyConfirmation', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getProductInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'ProductInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addProduct(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'AddProduct', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteProduct(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteProduct', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addStockpile(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'AddStockpile', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteStockpile(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteStockpile', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async useStockpile(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'UseStockpile', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addTraining(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'AddTrainingByAdmin', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteTraining(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteTrainingByAdmin', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async stopTraining(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'StopTrainingByAdmin', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addBookmark(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'AddBookmark', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteBookmark(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteBookmark', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addManual(formData) {
    this.setState({ isDataLoading: true });
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(ENDPOINT + 'AddManual', formData, headers)
      .then((rs) => {
        this.setState({ ...this.state, ...rs.data, isDataLoading: false });
      })
      .catch((e) => {
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
      });
  }

  async deleteManual(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteManual', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async editManual(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'EditManual', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getBannerInfo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'BannerInfo', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addBanner(formData) {
    this.setState({ isDataLoading: true });
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(ENDPOINT + 'AddBanner', formData, headers)
      .then((rs) => {
        this.setState({ ...this.state, ...rs.data, isDataLoading: false });
      })
      .catch((e) => {
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
      });
  }

  async deleteBanner(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteBanner', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async selectBanner(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'SelectBanner', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async fixedBanner(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'FixedBanner', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getChatHistory(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'ChatHistory', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async chatLogOutput(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'ChatLogOutput', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }
  async deleteOutputLink() {
    this.setState({ outputLink: false });
  }

  async getOfficeList(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'OfficeList', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          this.setState({ isDataLoading: false });
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addOffice(params) {
    this.setState({ isDataLoading: true });
    if (params.admin === 0) {
      //事業所による個別登録
      return axios
        .post(ENDPOINT + 'OfficeRegist', params)
        .then((rs) => {
          this.setState({ isDataLoading: false });
          if (rs.data) {
            return true;
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e)
          console.log("HTTP Request failed.");
          this.setState({ isDataLoading: false });
          return false;
        });
    } else {
      //本部による登録
      return axios
        .post(ENDPOINT + 'AddOffice', params)
        .then((rs) => {
          this.setState({ isDataLoading: false });
          if (rs.data) {
            this.setState({ ...this.state, ...rs.data, isDataLoading: false });
            return true;
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e)
          console.log("HTTP Request failed.");
          this.setState({ isDataLoading: false });
          return false;
        });
    }
  }

  async getPrefCityList(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'PrefCityNames', params)
      .then((rs) => {
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          this.setState({ isDataLoading: false });
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteOffice(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteOffice', params)
      .then((rs) => {
        if (rs.data) {
          this.setState({ ...this.state, ...rs.data, isDataLoading: false });
          return true;
        } else {
          this.setState({ isDataLoading: false });
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async resetPass(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'ResetPass', params)
      .then((rs) => {
        this.setState({ isDataLoading: false });
        if (rs.data) {
          return true;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getDamageAnnounces(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DamageAnnounces', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getDamageInspection(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DamageInspection', params)
      .then((rs) => {
        console.log(rs.data)
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async editDamageInspectionItem(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'EditDamageInspectionItem', params)
      .then((rs) => {

        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getPreventionTodoList(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'PreventionTodoList', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async addPreventionTodo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'AddPreventionTodo', params)
      .then((rs) => {
        this.setState(
          {
            isDataLoading: false,
          }
        );
        return true;
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deletePreventionTodo(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeletePreventionTodo', params)
      .then((rs) => {
        this.setState(
          {
            isDataLoading: false,
          }
        );
        return true;
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteStaff(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteStaff', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async editStaffName(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'EditStaffName', params)
      .then((rs) => {
        console.log('bbbb', rs)
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async deleteLineUser(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DeleteLineUser', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getTrainingStateList(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'TrainingStateList', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          this.setState({ isDataLoading: false });
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }

  async getDamageReports(params) {
    this.setState({ isDataLoading: true });
    return axios
      .post(ENDPOINT + 'DamageReports', params)
      .then((rs) => {
        this.setState(
          {
            ...this.state,
            ...rs.data,
            isDataLoading: false,
          }
        );
        if (rs.data) {
          return rs.data;
        } else {
          return false;
        }
      })
      .catch((e) => {
        console.log(e)
        console.log("HTTP Request failed.");
        this.setState({ isDataLoading: false });
        return false;
      });
  }



}