import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { EyeFill } from 'react-bootstrap-icons';

const TrainingContent = ({ globalState }) => {
  const {
    officeName,
    trainingList,
    isDataLoading
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getTrainingInfo(params);
  }

  useEffect(() => {
    loadFunc();
  }, []);


  const renderTrainingList = trainingList.map(
    v => {
      return (
        <tr key={`tr${v.id}`}>
          <td width="35%">{v.menu}</td>
          <td width="35%">{v.datetime}</td>
          <td width="30%">
            <Button variant="primary" size="sm" onClick={() => navigate(`/EachTraining/${v.id}`)}>
              <EyeFill size={24} color="#FFF" />
            </Button>
          </td>
        </tr>
      )
    });
  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 6, offset: 2 }}>
          <h5>訓練実績</h5>
          <Table striped size="sm">
            <thead>
              <tr>
                <th>訓練名</th>
                <th>訓練開始日時</th>
                <th>チャット表示</th>
              </tr>
            </thead>
            <tbody>
              {renderTrainingList}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
    </Container>
  )
}
const Training = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <TrainingContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Training;