import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditManualDirectryModal = (props) => {
  const { mdlShow, mdlSetHide, saveData, directryList, subDirectryList } =
    props;

  const [directryId, setDirectryId] = useState(0);
  const [subDirectryId, setSubDirectryId] = useState(0);
  useEffect(() => {
    setDirectryId(Number(props.directryId))
    setSubDirectryId(Number(props.subDirectryId))
  }, [props.directryId, props.subDirectryId]);
  const handleSaveData = () => {
    const params = {
      menu: 'directry',
      directryId: directryId,
      subDirectryId: subDirectryId
    }
    saveData(params);
    mdlSetHide();
  }

  const renderDirectryOpts = Object.keys(directryList) ? Object.keys(directryList)
    .map(id => <option key={`d${id}`} value={id}>{directryList[id].name}</option>) : ""
  const renderSubDirectryOpts =
    directryId !== 0 && Object.keys(subDirectryList) && directryList[directryId].subdirectry_ids.length ?
      directryList[directryId].subdirectry_ids.map(id => <option key={`s${id}`} value={id}>{subDirectryList[id].name}</option>) : ""
  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>マニュアルフォルダの編集</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>フォルダ／サブフォルダ</div>
        <div className="form-inline d-flex">
          <Form.Select
            as="select"
            size="sm"
            value={directryId}
            onChange={(e) => setDirectryId(Number(e.target.value))}
            style={{ marginRight: "10px" }}
          >
            <option value={0}>未定</option>
            {renderDirectryOpts}
          </Form.Select>
          <Form.Select
            as="select"
            size="sm"
            value={subDirectryId}
            onChange={(e) => setSubDirectryId(Number(e.target.value))}
          >
            <option value={0}>未定</option>
            {renderSubDirectryOpts}
          </Form.Select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => handleSaveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditManualDirectryModal;
