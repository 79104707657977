import { useNavigate, useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { BoxArrowLeft } from 'react-bootstrap-icons';

const AdminNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeKey = location.pathname.split("/")[1];
  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/Login");
  }
  return (
    <Navbar bg="light" variant="light">
      <Container>
        <Navbar.Brand href="">
          <img
            alt=""
            src={window.location.pathname.indexOf("Each") !== -1 ? `../AppIcon.png` : `./AppIcon.png`}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          MOSHIMO
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto my-2 my-lg-0"
            activeKey={`/${activeKey}`}
            onSelect={(selectedKey) => navigate(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link href="/officelist">事業所リスト</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/productlist">本部備蓄品リスト</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/banner">バナーリスト</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/damageAnnounceMap">被災情報MAP</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/preventionTodoList">防災TODO</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/trainingManagement">訓練管理</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="form-inline d-flex" >
            <Button variant="primary" size="sm" onClick={() => handleLogOut()}>
              <span style={{ marginRight: "10px", fontSize: "16px", verticalAlign: "middle" }}>本部管理</span>
              <BoxArrowLeft size={24} color="#FFF" />
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default AdminNav;