import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import moment from "moment";

import DataContainer from "../containers/DataContainer";

import AdminNav from "./AdminNav";
import Loading from "./Loading";

import AddTrainingModal from "./modals/AddTrainingModal";

const TrainingManagementContent = ({ globalState }) => {
  const {
    officeList,
    trainingStateList,
    diasterList,
    isDataLoading,
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const initData = {
    date: moment().format('YYYY-MM-DD'),
    time: "00:00",
    officeId: "",
    diasterId: 0,
  };
  const [data, setData] = useState(initData);
  const [mdlShow, setMdlShow] = useState(false);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    if (!admin || Number(admin) === 0) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");

    const params = { appId: appId, appPass: appPass };
    globalState.getTrainingStateList(params);

  }
  useEffect(() => {
    loadFunc();
  }, []);

  const handleStopTraining = (trainingId) => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    if (!window.confirm("この訓練を中止してもよろしいですか？")) return false;
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, trainingId: trainingId };
    globalState.stopTraining(params).then(rs => {
      if (rs) {
        alert('中止しました。');
      } else {
        alert('中止できませんでした。');
      }
    });
  }

  const handleCancelTraining = (trainingId) => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    if (!window.confirm("この訓練予定を解除してもよろしいですか？")) return false;
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, trainingId: trainingId };
    globalState.deleteTraining(params).then(rs => {
      if (rs) {
        alert('解除しました。');
      } else {
        alert('解除できませんでした。');
      }
    });
  }

  const handleSetData = (key, value) => {
    let sd = { ...data };
    sd[key] = value;
    setData(sd);
  }
  const handleShowAddTrainingModal = (officeId) => {
    handleSetData('officeId', officeId);
    setMdlShow(true);
  }
  const handleAddTraining = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      setMdlShow(false);
      navigate("/Login");
    }
    const trainingDatetime = `${data.date} ${data.time}`;
    const nowTime = moment().format('YYYY-MM-DD HH:mm');
    if (trainingDatetime <= nowTime) {
      alert("訓練開始日時は、現在日時以降に設定してください")
      return false;
    }
    if (data.diasterId === 0) {
      alert("災害内容が指定されていません")
      return false;
    }
    setMdlShow(false);
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, saveData: data };
    globalState.addTraining(params);
  }

  const renderTrainingManagement = (() => {
    const list = [];
    if (!Object.keys(officeList)) return list;
    Object.keys(officeList).forEach(id => {
      const isTraining = trainingStateList[id];
      const trainingState = isTraining ? trainingStateList[id].state : "";
      const datetime = isTraining ? moment(trainingStateList[id].time).format('M/D HH:mm') : "";
      const name = isTraining ? trainingStateList[id].name : "";
      const trainingId = isTraining ? trainingStateList[id].id : "";
      list.push(
        <tr key={`of${id}`}>
          <td>{officeList[id].name}</td>
          <td>
            {
              !isTraining ? <Button variant="primary" size="sm" onClick={() => handleShowAddTrainingModal(id)} >
                登録
              </Button> : trainingState === "schedule" ? <div>{`${name} ${datetime}開始予定 `}<Button variant="primary" size="sm" onClick={() => handleCancelTraining(trainingId)} >
                予定解除
              </Button></div> : <div>{`${name} 訓練中 `}<Button variant="primary" size="sm" onClick={() => handleStopTraining(trainingId)} >中止</Button></div>
            }
          </td>
          <td>

          </td>
        </tr>
      )
    })
    return list;
  })();
  return (
    <Container fluid >
      <AdminNav />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 7, offset: 2 }}>
          <h5>
            訓練状況リスト
          </h5>
          <Table>
            <thead>
              <tr>
                <th>事業所名</th>
                <th>訓練状況</th>
                <th>安否確認</th>
              </tr>
            </thead>
            <tbody>
              {renderTrainingManagement}
            </tbody>
          </Table>
        </Col>
      </Row>
      <AddTrainingModal
        saveData={handleAddTraining}
        setData={handleSetData}
        data={data}
        officeName={data.officeId && data.officeId !== 0 ? officeList[data.officeId].name : ""}
        diasterList={diasterList}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
      />
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
    </Container>
  )
}
const TrainingManagement = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <TrainingManagementContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default TrainingManagement;