import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Row, Col, Table, Image } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { staffPositions, staffPositionOrders } from "../config/config";
import QRcode from "../images/moshimoBotQRcode.png";


const HomeContent = ({ globalState }) => {
  const {
    officeName,
    prefectureCode,
    cityCode,
    email,
    managerName,
    prefNames,
    cityNames,
    lineId,
    staffList,
    lineUserList,
    isDataLoading
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");

  const navigate = useNavigate();
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getHomeInfo(params);

  }
  useEffect(() => {
    loadFunc();
  }, []);

  const renderStaffList = (() => {
    if (!staffList) return <></>;
    const staffIds = Object.keys(staffList);
    return staffIds
      .sort((a, b) => {
        if (staffList[a].position !== staffList[b].position) {
          return staffList[a].position < staffList[b].position ? -1 : 1;
        } else {
          return staffList[a].position_order < staffList[b].position_order ? -1 : 1;
        }
      })
      .map(id => {
        const order = Number(staffList[id].position_order);
        return (<tr key={`s${id}`}>
          <td>{staffPositions[staffList[id].position]}{staffPositionOrders.substring(order, order + 1)}</td>
        </tr>)
      })
  })();

  const renderLineUserList = (() => {
    if (!Object.keys(lineUserList).length) return <></>
    return Object.keys(lineUserList)
      .map(id => {
        return (<tr key={`lu${id}`}>
          <td>{lineUserList[id].user_name}</td>
        </tr>)
      })
  })();


  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: "auto", offset: 2 }}>
          <h5>基本情報</h5>
          <Table striped size="sm">
            <tbody>
              <tr><th>App ID</th><td>{sessionStorage.getItem("appId")}</td></tr>
              <tr><th>事業所名</th><td>{officeName}</td></tr>
              <tr><th>都道府県名</th><td>{prefNames[prefectureCode]}</td></tr>
              <tr><th>市町村名</th><td>{cityNames[cityCode]}</td></tr>
              <tr><th>担当者名</th><td>{managerName}</td></tr>
              <tr><th>登録メールアドレス</th><td>{email}</td></tr>
              <tr><th>LINE登録No</th><td>{lineId}</td></tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 3, offset: 2 }}>
          <h5>登録メンバー情報</h5>
          <Table striped size="sm">
            <tbody>
              {renderStaffList}
            </tbody>
          </Table>
        </Col>
        <Col md={{ span: 3 }}>
          <h5>LINEメンバー情報</h5>
          <Table striped size="sm">
            <tbody>
              {renderLineUserList}
            </tbody>
          </Table>
        </Col>
        <Col md={{ span: 3 }}>
          <h5>LINE QRコード</h5>
          <Image src={QRcode} width="100px" height="100px" />
        </Col>
      </Row> */}
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
    </Container>
  )
}
const Home = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <HomeContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Home;