import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import packageJson from "../../package.json";
import AddOfficeModal from "./modals/AddOfficeModal";



const LoginContent = ({ navigation, globalState }) => {
  const {
    prefNames,
    cityNames,
  } = globalState.state;
  const [appId, setAppId] = useState('');
  const [appPass, setAppPass] = useState('');
  // const [showPass, setShowPass] = useState('');
  const initData = {
    officeName: "",
    prefecture: "",
    city: "",
    email: "",
    manager: "",
  };
  const [data, setData] = useState(initData);
  const [mdlShow, setMdlShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    globalState.getPrefCityList();
  }, []);

  const officeLogin = () => {
    const params = { appId: appId, appPass: appPass };
    globalState.officeLogin(params).then(rs => {
      if (rs) {
        sessionStorage.setItem('appId', appId);
        sessionStorage.setItem('appPass', appPass);
        sessionStorage.setItem('officeId', rs.officeId);
        sessionStorage.setItem('admin', rs.admin);
        if (rs.admin === 1) {
          navigate('/officelist');
        } else {
          navigate('/home');
        }

      } else {
        alert('ログインできませんでした。');
      }
    })
  }

  const saveData = () => {
    setMdlShow(false);
    const params = { appId: appId, appPass: appPass, ...data, admin: 0 };
    globalState.addOffice(params);
  }

  const handleSetData = (key, value) => {
    let sd = { ...data };
    sd[key] = value;
    setData(sd);
  }


  return (
    <Container fluid >
      <Row>
        <Col className="d-flex align-items-center" style={{ minHeight: "600px" }}>
          <div className="d-block mx-auto">
            <div style={{ marginBottom: "10px", width: "100%", textAlign: "center" }}>福祉事業所向け防災システム「MOSHIMO」<br /></div>
            <div style={{ marginBottom: "50px", width: "100%", textAlign: "center" }}>管理者用サイト</div>
            <img
              alt=""
              src="./AppIcon.png"
              width="80"
              height="80"
              className="d-block mx-auto"
            />

            <div style={{ marginTop: "20px", width: "100%", textAlign: "center" }}>{packageJson.version}</div>
            <div
              className="linktext"
              style={{ marginTop: "50px", width: "100%", textAlign: "center" }}
              _hover={{ color: "#dc3545" }}
              onClick={() => navigate('/about')}
            >
              「MOSHIMO」とは
            </div>
            <div
              className="linktext"
              style={{ marginTop: "10px", width: "100%", textAlign: "center" }}
              onClick={() => navigate('/privacypolycy')}
            >
              プライバシーポリシー
            </div>
            <div
              className="linktext"
              style={{ marginTop: "10px", width: "100%", textAlign: "center" }}
            >

              <a style={{ color: "inherit", textDecoration: "none" }} href="https://moshimo.reimei-x.link/files/moshimo_user_manual.pdf" target="new">
                ユーザー運用マニュアル
              </a>
            </div>
            <div style={{ textAlign: "center", width: "100%", marginBottom: "5px", marginTop: "10px", fontWeight: "bold" }}>お知らせ</div>
            <div style={{ border: "solid 1px #333", width: "70%", marginLeft: "15%", borderRadius: "8px", padding: "8px" }}>

              &nbsp;&nbsp;&nbsp;現在、テスト運用中の為、無料会員様にも、全ての有料会員限定の機能を開放しております。
              機能の詳細については、ユーザー運用マニュアルをご覧ください。<br />
              &nbsp;&nbsp;&nbsp;またシステム改善のため、利用ログを取得させていただいております。
              ご理解のほど、よろしくお願いいたします。
            </div>
          </div>
        </Col>
        <Col className="d-flex align-items-center" style={{ minHeight: "600px" }}>
          <Form >
            <Form.Group className="mb-3">
              <Form.Label>App ID</Form.Label>
              <Form.Control type="text" placeholder="App ID" value={appId} onChange={(e) => setAppId(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>パスワード</Form.Label>
              <Form.Control type="password" placeholder="パスワード" value={appPass} onChange={(e) => setAppPass(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" label="パスワードを保存" />
            </Form.Group>
            <Button variant="primary" type="button" onClick={() => officeLogin()}>
              ログイン
            </Button>
            <br /><br />
            <Button variant="primary" type="button" onClick={() => setMdlShow(true)}>
              新規事業所登録
            </Button>
          </Form>
        </Col>
      </Row>
      <AddOfficeModal
        saveData={saveData}
        setData={handleSetData}
        data={data}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
        prefNames={prefNames}
        cityNames={cityNames}
        admin={0}
      />
    </Container>
  )
}
const Login = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <LoginContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Login;