// export const ENDPOINT = "http://192.168.1.15/api/";
//export const ENDPOINT = "http://192.168.11.3/api/";

// export const ENDPOINT = "https://moshimo.otasuke.work/api/";
export const ENDPOINT = "https://reimei-x.link/moshimo-api/public/api/";

export const MANUALS = "https://reimei-x.link/moshimo-api/storage/app/public/manuals/";
export const BANNERS = "https://reimei-x.link/moshimo-api/storage/app/public/banners/";
export const INSPECTION_PHOTO_DIR = "https://reimei-x.link/moshimo-api/storage/app/public/inspection/";
export const DAMAGES_PHOTO_DIR =
    "https://reimei-x.link/moshimo-api/storage/app/public/damages/";