import React from "react";
import { Modal, Button, Form } from "react-bootstrap";



const PutSafetyConfirmationModal = (props) => {
  const { mdlShow, mdlSetHide, saveData, data, setData } =
    props;

  const handleSaveData = () => {
    // if (data.name === "" || data.pcs === "" || data.barcode === "") {
    //   alert("必要な項目が記入または選択されていません");
    //   return false;
    // }
    saveData();
  }

  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>安否確認送信</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        送信内容
        <Form.Control
          as="textarea"
          size="sm"
          value={data.content}
          onChange={(e) => setData("content", e.target.value)}
          rows={5}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => handleSaveData()}>
          送　信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PutSafetyConfirmationModal;
