import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table, Image, Form } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { PlusLg, EyeFill, Trash } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import AddBannerModal from "./modals/AddBannerModal";
import { BANNERS } from "../config/endpoint";

const BannerContent = ({ globalState }) => {
  const {
    officeName,
    bannerList,
    isDataLoading,
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const [mdlShow, setMdlShow] = useState(false);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    // const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    // const officeId = admin === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass };
    globalState.getBannerInfo(params);
  }


  useEffect(() => {
    loadFunc();
  }, []);


  const handleSelectBanner = (id) => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = {
      appId: appId,
      appPass: appPass,
      id: id
    };
    globalState.selectBanner(params);
  }

  const handleFixedBanner = (id) => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = {
      appId: appId,
      appPass: appPass,
      id: id
    };
    globalState.fixedBanner(params);
  }

  const saveData = (file, title, link) => {
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const formData = new FormData();
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    // const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    // const officeId = admin === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const fileName = `${uuidv4()}`;
    const ext = title.split('.')[1];
    formData.append("appId", appId);
    formData.append("appPass", appPass);
    // formData.append("officeId", officeId);
    formData.append("link", link);
    formData.append("fileName", `${fileName}.${ext}`);
    formData.append("file", file);
    globalState.addBanner(formData);
  }

  const handleDeleteBanner = (id) => {
    if (!window.confirm("このバナーを削除してもよろしいですか？")) return false;

    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");

    const params = { appId: appId, appPass: appPass, bannerId: id };
    globalState.deleteBanner(params);
  }
  const renderBannerList = bannerList.map(
    v => {
      return (
        <tr key={`ma${v.id}`}>
          <td width="10%">
            <Form.Check type="checkbox" checked={v.fixed === 1 ? true : false} onClick={() => handleFixedBanner(v.id)} />
          </td>
          <td width="10%">
            <Form.Check type="checkbox" checked={v.select_flag === 1 ? true : false} onClick={() => handleSelectBanner(v.id)} />
          </td>
          <td width="10%">{v.id}</td>
          <td width="60%">
            <Image src={`${BANNERS}${v.name}`} />
          </td>
          <td width="10%">
            <a href={v.link} target="_blank" rel="noreferrer">
              <Button variant="primary" size="sm">
                <EyeFill size={24} color="#FFF" />
              </Button>
            </a>
          </td>
          <td width="10%">
            <Button variant="primary" size="sm" onClick={() => handleDeleteBanner(v.id)}>
              <Trash size={24} color="#FFF" />
            </Button>
          </td>
        </tr>
      )
    });

  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 8, offset: 2 }}>
          <h5>
            バナーリスト
            <Button variant="primary" size="sm" style={{ marginLeft: "20px" }} onClick={() => setMdlShow(true)}>
              <PlusLg size={24} color="#FFF" />
            </Button>
          </h5>
          <Table striped size="sm" width="100%">
            <thead>
              <tr>
                <td width="10%">固定</td>
                <td width="10%">選択</td>
                <th width="10%">id</th>
                <td width="50%"></td>
                <td width="10%"></td>
                <td width="10%"></td>
              </tr>
            </thead>
            <tbody>
              {renderBannerList}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <AddBannerModal
        saveData={saveData}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
      />
    </Container>
  )
}
const Banner = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <BannerContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Banner;