import React from "react";
import { useNavigate } from "react-router-dom";
const About = () => {
    const navigate = useNavigate();
    return (
        <div style={{ marginLeft: "10%", marginRight: "5%", marginTop: "30px" }}>
            <img
                alt=""
                src="./AppIcon.png"
                width="40"
                height="40"
                style={{ display: "inline-block" }}
                onClick={() => navigate('/')}
            />
            <span style={{ fontSize: "20px", marginLeft: "10px" }}>「MOSHIMO」とは</span>

            <div style={{ marginTop: "20px" }}>
                <p></p>
                <p>「MOSHIMO」は、福祉事業所向けの防災システムです。<br />
                    防災に関する情報を一元化し、その情報を事業者内のスタッフ間で共有することで、防災対策をより効果的に実施することが可能になります。
                    <br />また社会福祉連携推進法人内での情報共有や、市町村など自治体への情報提供に役立てる事も可能です。
                    <br />このシステムは、個々のスタッフが使用するスマホアプリと全体の管理機能をもつWEBアプリ（パソコン）で構成されます。</p>
                <p>主な機能は以下の通りです。</p>
                <p>1.災害情報の通知機能
                    <br />気象庁の気象庁防災情報に基づき、登録された市町村に警報が発令された時、アプリを通して、スマホに警報情報を通知します。
                    <br />なお、より詳細な防災情報をもつLアラートへの登録を申請中です。
                </p>
                <p>2.避難場所及びハザードマップのブックマーク機能
                    <br />避難場所及びハザードマップのURL情報をブックマークすることができ、スマホで閲覧、またスタッフ間で共有することができます。</p>
                <p>3.防災マニュアルの登録機能（有料版のみ）
                    <br />事業所で作成したマニュアル（PDF形式）を登録することができ、スマホアプリでいつでも確認することができます。<br />無料版は、デフォルトマニュアルの閲覧のみになります。</p>
                <p>４.備蓄品の管理機能
                    <br />備蓄品を品目、賞味期限別に登録、消費管理することができます。</p>
                <p>５.チャット機能（有料版のみ）
                    <br />災害発生時及び訓練開始時に、チャットを開設することができ、スタッフ間でメッセージ及び写真を共有することができます。</p>
                <p>6.訓練機能（有料版のみ）
                    <br />訓練日時を登録することで、防災訓練をシュミレートできます。<br />
                    また訓練を終了した後、その訓練時のチャット履歴を出力できます（CSV形式）</p>
                <p>7.被災情報発信機能
                    <br />スマホから被災情報を発信でき、その情報は、パソコン上で共有できます。</p>
            </div>
        </div>
    );
};

export default About;