import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";

const AddBookmarkModal = (props) => {
  const { mdlShow, mdlSetHide, data, setData, label, saveData, deleteData, category } =
    props;
  const categoryName = category === "hazardMapLinks" ? "ハザードマップ" : "避難場所";
  const title = label === "new" ? `${categoryName}ブックマークの新規登録` : `${categoryName}ブックマークの修正・削除`;

  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="table" size="sm">
          <tbody>
            <tr>
              <th>ブックマーク名</th>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                />
              </td>

            </tr>
            <tr>
              <th>URL</th>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={data.link}
                  onChange={(e) => setData("link", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </Table>

      </Modal.Body>
      <Modal.Footer>
        {label === "edit" ?
          <Button variant="danger" onClick={() => deleteData()} >
            削　除
          </Button> : <></>}
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => saveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBookmarkModal;
