import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";



const AddStockpileModal = (props) => {
  const { mdlShow, mdlSetHide, data, setData, label, saveData, deleteData } =
    props;
  const title = label === "new" ? "備蓄品の新規登録" : "備蓄品データの修正・削除";
  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="table" size="sm">
          <tbody>
            <tr>
              <th>品目名</th>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                />
              </td>

            </tr>
            <tr>
              <th>登録数量</th>
              <td>
                <Form.Control
                  type="number"
                  size="sm"
                  value={data.amount}
                  onChange={(e) => setData("amount", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>賞味期限</th>
              <td>
                <Form.Control
                  type="date"
                  size="sm"
                  value={data.expiry_date}
                  onChange={(e) => setData("expiry_date", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        {label === "edit" ?
          <Button variant="danger" onClick={() => deleteData()} >
            削　除
          </Button> : <></>}
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => saveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStockpileModal;
