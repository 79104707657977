import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table, Image } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";

const EachTrainingContent = ({ globalState }) => {
  const {
    officeName,
    isDataLoading
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const pdata = useParams();
  const [chatHistory, setChatHistory] = useState([]);
  const [trainingInfo, setTrainingInfo] = useState({});


  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, trainingId: pdata.paramId };
    globalState.getChatHistory(params).then(rs => {
      setChatHistory(rs.chatHistory);
      setTrainingInfo(rs.trainingInfo);
    });
  }

  useEffect(() => {
    loadFunc();
  }, []);
  const renderChatHistory = (() => {
    const list = [];
    chatHistory.forEach(
      v => {
        list.push(
          <div key={`ch${v._id}`} className="history-line">
            <div width="28%" className="history-content w28">{v.createdAt}</div>
            <div width="13%" className="history-content w13">{v.user.name}</div>
            <div width="48%" className="history-content w48">{
              v.photo === 1 ? <Image src={v.image} alt="photo" width="80%" height="80%" style={{ marginBottom: "10px" }} /> : <>{v.text}</>
            }</div>
          </div>
        )
      });
    return list;
  })();
  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} />
      <div style={{ overflowY: "scroll", maxHeight: "600px" }}>
        <Row style={{ marginTop: "50px" }}>
          <Col md={{ span: 8, offset: 1 }}>
            <h5>訓練実績</h5>
            <Table size="sm">
              <tr>
                <th>訓練名</th><td>{trainingInfo.menu || ""}</td>
                <th>訓練開始日時</th><td>{trainingInfo.datetime || ""}</td>
              </tr>
            </Table>
            <br />
            <div style={{ width: "100%" }}>
              {renderChatHistory}
            </div>
          </Col>
        </Row>
        <Loading loadingDis={isDataLoading ? "block" : "none"} />
      </div>
    </Container>
  )
}
const EachTraining = () => {
  return (
    <>
      <Subscribe to={[DataContainer]}>
        {(globalState) => (
          <EachTrainingContent globalState={globalState} />
        )}
      </Subscribe>
    </>
  );
};

export default EachTraining;