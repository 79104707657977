import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import moment from "moment";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import DataContainer from "../containers/DataContainer";

import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";

import PutSafetyConfirmationModal from "./modals/PutSafetyConfirmationModal";
import { safetyConfirmationDic } from "../config/config";

const SafetyCinfirmationContent = ({ globalState }) => {
  const {
    officeName,
    safetyConfirmationList,
    lineUserList,
    alertState,
    isDataLoading,
  } = globalState.state;

  const navigate = useNavigate();
  const admin = sessionStorage.getItem("admin");
  const [mdlShow, setMdlShow] = useState(false);
  const [putData, setPutData] = useState({
    content: "（訓練）安否情報を送信してください。"
  })

  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    // const officeId = sessionStorage.getItem("officeId");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getSafeConfirmationInfo(params);
  }

  useEffect(() => {
    loadFunc();
  }, []);

  const handlePutSafetyConfirmation = () => {
    if (alertState === 0) {
      alert("現在、警報発令もなく、訓練中でもない為、安否確認送信はできません");
      return false;
    }
    if (safetyConfirmationList['admin'] && safetyConfirmationList['admin'].length) {
      const list = safetyConfirmationList['admin'].sort((a, b) => { return a.created_at > b.created_at ? -1 : 1 });
      const now = moment();
      const duration = moment.duration(now.diff(moment(list[0].created_at)), 'milliseconds');
      const secondsFromLastPutTime = duration._milliseconds / 1000;
      //送信は１時間以上、間隔を空ける　１時間　3600s
      if (secondsFromLastPutTime < 3600 * 1) {
        alert("送信は１時間以上、間隔を空けて行ってください");
        return false;
      }
    }
    setMdlShow(true);
  }

  const handleSetData = (key, value) => {
    setPutData({ ...putData, [key]: value });
  }

  const saveData = () => {
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    // const officeId = sessionStorage.getItem("officeId");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId, ...putData, category: "put", };
    globalState.putSafetyConfirmation(params).then(rs => {
      if (rs) {
        alert('送信しました。');
      } else {
        alert('送信できませんでした。');
      }
    });;
  }
  const updateTime = moment().format("M/D HH:mm");
  const lastPutTime = (() => {
    if (!safetyConfirmationList['admin']) return "未送信";
    const list = safetyConfirmationList['admin'].sort((a, b) => { return a.created_at > b.created_at ? -1 : 1 });

    return moment(list[0].created_at).format("M/D HH:mm");
  })();
  const notRecievedUserNames = Object.keys(lineUserList).filter((userId) => { return !safetyConfirmationList[userId] }).map(userId => lineUserList[userId].user_name);

  const getlatestCreatedAt = (datas) => {
    if (!datas || !datas.length) return false;
    datas.sort((a, b) => { return a['created_at'] > b['created_at'] ? -1 : 1 });
    return datas[0]['created_at'];
  }
  const receivedUserIds = Object.keys(lineUserList)
    .filter((userId) => { return safetyConfirmationList[userId] })
    .sort((a, b) => {
      return getlatestCreatedAt(safetyConfirmationList[a]) > getlatestCreatedAt(safetyConfirmationList[b]) ? -1 : 1;
    });

  const renderSafetyConfirmationList = (() => {
    const list = [];
    // Object.keys(lineUserList).forEach(userId => {
    receivedUserIds.forEach(userId => {
      const rowspan = safetyConfirmationList[userId] ? safetyConfirmationList[userId].length : false;
      const userName = lineUserList[userId].user_name;
      if (!rowspan) {
        list.push(
          <tr key={`un${userId}`}>
            <td>{userName}</td>
            <td>未受信</td>
            <td></td>
          </tr>
        )
      } else {
        safetyConfirmationList[userId].forEach((v, index) => {
          const createdAt = moment(v.created_at).format("M/D HH:mm");
          const contents = [];

          switch (v.category) {
            case "static":
              contents.push(`被災状況:${safetyConfirmationDic[v.content.damage_status]}`);
              contents.push(`現在地:${safetyConfirmationDic[v.content.current_location]}`);
              contents.push(`出社可否:${safetyConfirmationDic[v.content.can_come]}`);
              break;
            case "free":
              contents.push(v.content.content);
              break;
            case "location":
              contents.push(`住所:${v.content.address}`);
              contents.push(`緯度:${v.content.latitude}`);
              contents.push(`経度:${v.content.longitude}`);
              break;
            default:
              break;
          }
          if (rowspan > 1 && Number(index) === 0) {
            list.push(
              <tr key={`sc${v.id}`} >
                <td rowSpan={rowspan}>{userName}</td>
                <td>{contents.map((v, i) => <div key={`sc${v.id}_c${i}`}>{v}</div>)}</td>
                <td>{createdAt}</td>
              </tr>
            );
          } else {
            list.push(
              <tr key={`sc${v.id}`} >
                {Number(index) === 0 ? <td>{userName}</td> : <></>}
                <td>{contents.map((v, i) => <div key={`sc${v.id}_c${i}`}>{v}</div>)}</td>
                <td>{createdAt}</td>
              </tr>
            );
          }
        })
      }
    })
    return list;
  })();

  const totalizeData = (() => {
    const data = {
      users: 0,
      receptions: 0,
      no_receptions: 0,
      isaaffected_and_injured: { in_office: 0, at_home: 0, out_of_town: 0 },
      affected_not_injured: { in_office: 0, at_home: 0, out_of_town: 0 },
      not_affected: { in_office: 0, at_home: 0, out_of_town: 0 },
    };
    data.users = Object.keys(lineUserList).length || 0;
    Object.keys(lineUserList).forEach(userId => {
      if (safetyConfirmationList[userId]) {
        let confirmations = safetyConfirmationList[userId]
          .filter(v => { return v.category === "static" })
          .sort((a, b) => { return a.created_at > b.created_at ? -1 : 1 });
        if (!confirmations.length || confirmations.length === 0) {
          data.no_receptions++;
        } else {
          data.receptions++;
          data[confirmations[0]['content']['damage_status']][confirmations[0]['content']['current_location']]++;
        }
      } else {
        data.no_receptions++;
      };
    })
    return data;
  })();

  const affectedUserNames = (() => {
    const names = { isaaffected_and_injured: [], affected_not_injured: [] };
    Object.keys(lineUserList).forEach(userId => {
      if (safetyConfirmationList[userId]) {
        let confirmations = safetyConfirmationList[userId]
          .filter(v => { return v.category === "static" })
          .sort((a, b) => { return a.created_at > b.created_at ? -1 : 1 });
        if (confirmations.length !== 0) {
          const damage_status = confirmations[0]['content']['damage_status'];
          if (names[damage_status]) {
            names[damage_status].push(lineUserList[userId].user_name);
          }
        }
      }
    })
    return names;
  })();
  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 9, offset: 1 }}>
          <h5>
            安否確認状況
            <Button variant={alertState === 0 ? "dark" : "warning"} size="sm" style={{ marginLeft: "20px" }} onClick={() => handlePutSafetyConfirmation()}>
              安否確認送信
            </Button>
            <span style={{ display: "inline-block", marginLeft: "10px", fontSize: "14px" }}>
              最終送信日時:{lastPutTime}
            </span>
            <Button variant="primary" size="sm" style={{ marginLeft: "20px" }} onClick={() => loadFunc()}>
              <ArrowClockwise size={24} color="#FFF" />
            </Button>
            <span style={{ display: "inline-block", marginLeft: "10px", fontSize: "14px" }}>
              更新日時:{updateTime}
            </span>
          </h5>
          <Row>
            <h6 style={{ fontWeight: "bold", textDecoration: "underline" }}>概　況</h6>
            <Col md={4}>
              <Table>
                <tbody>
                  <tr><td>登録ユーザー数</td><td>{totalizeData.users}</td></tr>
                  <tr><td>受信ユーザー数</td><td>{totalizeData.receptions}</td></tr>
                  <tr><td>未受信ユーザー数</td><td>{totalizeData.no_receptions}</td></tr>
                </tbody>
              </Table>
            </Col>
            <Col md={8}>
              <Table>
                <tbody>
                  <tr>
                    <td>被災（外傷あり）</td>
                    <td>
                      事業所内:{totalizeData.isaaffected_and_injured.in_office}
                      /自宅:{totalizeData.isaaffected_and_injured.at_home}
                      /外出先:{totalizeData.isaaffected_and_injured.out_of_town}
                    </td>
                  </tr>
                  <tr>
                    <td>被災（外傷なし）</td>
                    <td>
                      事業所内:{totalizeData.affected_not_injured.in_office}
                      /自宅:{totalizeData.affected_not_injured.at_home}
                      /外出先:{totalizeData.affected_not_injured.out_of_town}
                    </td>
                  </tr>
                  <tr>
                    <td>被災なし</td>
                    <td>
                      事業所内:{totalizeData.not_affected.in_office}
                      /自宅:{totalizeData.not_affected.at_home}
                      /外出先:{totalizeData.not_affected.out_of_town}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <h6 style={{ fontWeight: "bold", textDecoration: "underline" }}>被災者リスト</h6>
          <Table>
            <tbody>
              <tr><td>外傷あり</td><td>{affectedUserNames.isaaffected_and_injured.join("・")}</td></tr>
              <tr><td>外傷なし</td><td>{affectedUserNames.affected_not_injured.join("・")}</td></tr>
            </tbody>
          </Table>
          <h6 style={{ fontWeight: "bold", textDecoration: "underline" }}>未受信者リスト</h6>
          <Table>
            <tbody>
              <tr><td>{notRecievedUserNames.join("・")}</td></tr>
            </tbody>
          </Table>
          <h6 style={{ fontWeight: "bold", textDecoration: "underline" }}>受信記録</h6>
          <div style={{ overflowY: "scroll", maxHeight: "500px", paddingBottom: "100px" }}>
            <Table>
              <thead>
                <tr><th>職員名</th><th>状況</th><th>受信日時</th></tr>
              </thead>
              <tbody>
                {renderSafetyConfirmationList}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <PutSafetyConfirmationModal
        saveData={saveData}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
        data={putData}
        setData={handleSetData}
      />
    </Container>
  )
}
const SafetyCinfirmation = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <SafetyCinfirmationContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default SafetyCinfirmation;