import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Modal, Button, Form } from 'react-bootstrap';

const AddBannerModal = (props) => {
    const { mdlShow, mdlSetHide, saveData } = props;
    const [isFile, setIsFile] = useState(false);
    const [title, setTitle] = useState("");
    const [file, setFile] = useState({});
    const [link, setLink] = useState("");

    function FileDropzone() {
        const onDrop = useCallback(acceptedFiles => {

            if (acceptedFiles[0].type !== "image/jpeg" && acceptedFiles[0].type !== "image/png") {
                alert("画像ファイルは、jpg形式かpng形式にしてください。");
                return false;
            }
            setTitle(acceptedFiles[0].name);
            setIsFile(true);
            setFile(acceptedFiles[0]);
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
        const cln = isDragActive ? 'dropzone--isActive' : '';
        return (
            <div {...getRootProps()} className={`dropzone ${cln}`}>
                <input {...getInputProps()} />
            </div>
        )
    }
    const handleSave = () => {
        if (link === "") {
            alert("リンク情報が入力されていません。");
            return false;
        }
        saveData(file, title, link);
        setIsFile(false)
    }
    const renderForm = <><label>リンク</label><Form.Control
        type="text"
        size="sm"
        label="リンク"
        value={link}
        onChange={(e) => setLink(e.target.value)}
    /></>
    return (
        <Modal show={mdlShow} onHide={mdlSetHide}>
            <Modal.Header closeButton>
                バナーの追加
            </Modal.Header>
            <Modal.Body>
                {isFile ? renderForm :
                    <>
                        <FileDropzone />
                        <p>下のボックスにバナー画像をドロップするか、<br />クリックして、バナー画像を選択してください。</p>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {isFile ? <Button variant="primary" onClick={() => handleSave()}>
                    登　録
                </Button> : <></>}
                <Button variant="secondary" style={{ marginLeft: "8px" }} onClick={() => { setIsFile(false); mdlSetHide() }}>
                    キャンセル
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddBannerModal;