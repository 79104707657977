import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { PlusLg, EyeFill, Trash, Folder, Bookmark } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import AddManualModal from "./modals/AddManualModal";
import EditManualDirectryModal from "./modals/EditManualDirectryModal";
import EditManualSearchTabModal from "./modals/EditManualSearchTabModal";
import { MANUALS } from "../config/endpoint";

const ManualContent = ({ globalState }) => {
  const {
    officeName,
    manualList,
    manualDirectryList,
    manualSubDirectryList,
    manualSearchTabList,
    isDataLoading,
    paid,
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const [mdlShow, setMdlShow] = useState(false);
  const [mdlShow2, setMdlShow2] = useState(false);
  const [mdlShow3, setMdlShow3] = useState(false);
  const [manualId, setManualId] = useState(0);
  const [directryId, setDirectryId] = useState(0);
  const [subDirectryId, setSubDirectryId] = useState(0);
  const [tabIds, setTabIds] = useState([]);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getManualInfo(params);
  }

  useEffect(() => {
    loadFunc();
  }, []);

  const handlePaidCheck = () => {
    if (Number(paid) === 0) {
      alert("マニュアル登録は、有料会員限定となっております。");
      return false;
    }
    setMdlShow(true);
  }

  const handleEditDirectry = (manualId, directryId, subDirectryId) => {
    setDirectryId(directryId);
    setSubDirectryId(subDirectryId);
    setManualId(manualId);
    setMdlShow2(true);
  }
  const handleEditSearchTab = (manualId, tabIds) => {
    setManualId(manualId);
    setTabIds(tabIds);
    setMdlShow3(true);
  }
  const saveData = (file, title) => {
    if (!title) {
      alert("タイトル名が登録されていません");
      return false;
    }
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const formData = new FormData();
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const fileName = `${uuidv4()}`;

    formData.append("appId", appId);
    formData.append("appPass", appPass);
    formData.append("officeId", officeId);
    formData.append("title", title);
    formData.append("fileName", `${fileName}.pdf`);
    formData.append("file", file);
    globalState.addManual(formData);
  }
  const handleDeleteManual = (id) => {
    if (!window.confirm("このマニュアルを削除してもよろしいですか？")) return false;

    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = sessionStorage.getItem("officeId");

    const params = { appId: appId, appPass: appPass, officeId: officeId, manualId: id };
    globalState.deleteManual(params);
  }
  const handleEditManual = (params) => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    params['appId'] = sessionStorage.getItem("appId");
    params['appPass'] = sessionStorage.getItem("appPass");
    params['officeId'] = sessionStorage.getItem("officeId");
    params['manualId'] = manualId;
    globalState.editManual(params);
  }
  const renderManualList = manualList.map(
    v => {
      const dirStr = v.directry_id === 0 ? "未設定" : manualDirectryList[v.directry_id].name;
      const subDirStr = v.directry_id === 0 ? "" :
        (v.sub_directry_id === 0 ? "" : `／${manualSubDirectryList[v.sub_directry_id].name}`)
      const tabStr = v.tab_ids && v.tab_ids.length ? v.tab_ids.map(id => manualSearchTabList[Number(id)].name).join("／") : "";
      return (
        <tr key={`ma${v.id}`}>
          <td width="34%">{v.title}</td>
          <td width="28%" style={{ textAlign: "left" }}>
            <Button variant="primary" size="sm" onClick={() => handleEditDirectry(v.id, v.directry_id, v.sub_directry_id)} style={{ marginRight: "8px" }}>
              <Folder size={24} color="#FFF" />
            </Button>
            {dirStr}{subDirStr}
          </td>
          <td width="28%">
            <Button variant="primary" size="sm" onClick={() => handleEditSearchTab(v.id, v.tab_ids && v.tab_ids.length ? v.tab_ids : [])} style={{ marginRight: "8px" }}>
              <Bookmark size={24} color="#FFF" />
            </Button>
            {tabStr}
          </td>
          <td width="5%">
            <a href={`${MANUALS}${v.name}`} target="_blank" rel="noreferrer">
              <Button variant="primary" size="sm">
                <EyeFill size={24} color="#FFF" />
              </Button>
            </a>
          </td>
          <td width="5%">
            <Button variant="primary" size="sm" onClick={() => handleDeleteManual(v.id)}>
              <Trash size={24} color="#FFF" />
            </Button>
          </td>
        </tr>
      )
    });

  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 10, offset: 1 }}>
          <h5>
            マニュアルリスト
            <Button variant="primary" size="sm" style={{ marginLeft: "20px" }} onClick={() => handlePaidCheck()}>
              <PlusLg size={24} color="#FFF" />
            </Button>
          </h5>
          <Table striped size="sm" width="100%">
            <thead>
              <tr>
                <th width="40%">タイトル</th>
                <td width="22%">フォルダ</td>
                <td width="22%">検索タブ</td>
                <td width="8%"></td>
                <td width="8%"></td>
              </tr>
            </thead>
            <tbody>
              {renderManualList}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <AddManualModal
        saveData={saveData}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
      />
      <EditManualDirectryModal
        saveData={handleEditManual}
        mdlShow={mdlShow2}
        directryList={manualDirectryList}
        subDirectryList={manualSubDirectryList}
        directryId={directryId}
        subDirectryId={subDirectryId}
        mdlSetHide={() => setMdlShow2(false)}
      />
      <EditManualSearchTabModal
        saveData={handleEditManual}
        mdlShow={mdlShow3}
        tabList={manualSearchTabList}
        tabIds={tabIds}
        mdlSetHide={() => setMdlShow3(false)}
      />
    </Container>
  )
}
const Manual = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <ManualContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Manual;