import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import moment from "moment";
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import "../css/DI.css"
import { INSPECTION_PHOTO_DIR } from "../config/endpoint";


const DamageinspectionContent = ({ globalState }) => {
  const {
    officeName,
    isDataLoading,
    inspectionCategoryList,
    inspectionSubCategoryList,
    inspectionItemList,
    inspectionRecords,
    invisibleItemIds,
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const [editItem, setEditItem] = useState(false);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getDamageInspection(params);
  }

  useEffect(() => {
    loadFunc();
  }, []);


  const getItemContent = (categoryId, itemId) => {
    const _item = inspectionItemList[categoryId].filter(item => {
      return Number(itemId) === Number(item.id)
    });
    return _item[0].content;
  }
  const handeleEditItem = (itemId, state) => {

    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const params = {};
    params['appId'] = sessionStorage.getItem("appId");
    params['appPass'] = sessionStorage.getItem("appPass");
    params['officeId'] = sessionStorage.getItem("officeId");
    params['itemId'] = itemId;
    params['state'] = state;
    globalState.editDamageInspectionItem(params);

  }
  const renderInspectionRecords = (() => {
    const list = [];
    Object.keys(inspectionCategoryList).forEach(categoryId => {
      const category = inspectionCategoryList[categoryId];
      list.push(<div key={`cat${categoryId}`} className="category">{category.name}</div>);
      category.sub_category_ids.forEach(subId => {
        const subCategory = inspectionSubCategoryList[subId];
        list.push(<div key={`sub${subId}`} className="subCategory">{subCategory.name}</div>);

        subCategory.item_ids.forEach(itemId => {
          const invisibleState = invisibleItemIds.indexOf(itemId) !== -1;
          const invisibleStyle = invisibleState ? { textDecoration: "line-through" } : {};
          const editB = editItem && Number(categoryId) !== 1 ?
            <Button size="sm" style={{ marginLeft: "10px" }} onClick={() => handeleEditItem(itemId, invisibleState ? 1 : 0)}>{invisibleState ? "表示" : "非表示"}</Button> : <></>
          list.push(<div key={`ite${itemId}`} style={invisibleStyle} className="item">{getItemContent(categoryId, itemId)}{editB}</div>)
          if (!editItem) {
            if (inspectionRecords[itemId]) {
              inspectionRecords[itemId].forEach((record, i) => {
                list.push(
                  <div key={`rec${itemId}-${i}`} className="recordTitle">
                    {`【${moment(record.created_at).format("MM/DD HH:mm")}    ${record.staffName}】`}
                  </div>
                )
                if (record.content.selectText && record.content.selectText !== "") {
                  list.push(<div key={`st${itemId}-${i}`} className="selectText">
                    <img
                      className="textIcon"
                      alt=""
                      src="./images/pen1.png"
                      width="18"
                      height="18"

                    />
                    <span>{record.content.selectText}</span>
                  </div>)
                }
                if (record.content.freeText && record.content.freeText !== "") {
                  list.push(<div key={`ft${itemId}-${i}`} className="freeText">
                    <img
                      className="textIcon"
                      alt=""
                      src="./images/pen2.png"
                      width="18"
                      height="18"
                    />
                    <span>{record.content.freeText}</span>
                  </div>)
                }
                if (record.content.photo && record.content.photo !== "") {
                  list.push(<div key={`rp${itemId}-${i}`} className="recordPhoto">
                    <img
                      alt="record_photo"
                      src={`${INSPECTION_PHOTO_DIR}${record.content.photo}`}
                    />
                  </div>)
                }
              })
            } else {
              list.push(<div key={`norecord${itemId}`} className="noRecord">記録なし</div>)
            }

          }
        }
        )

      })
    })
    return list;
  })()

  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 8, offset: 2 }}>
          <h5>被災点検記録</h5>
          <div>
            <Form.Check type="checkbox" checked={editItem} label="項目編集(表示・非表示)" onClick={() => setEditItem(!editItem)} />
          </div>
          <div className="inspectionRecords">
            {renderInspectionRecords}
          </div>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
    </Container>
  )
}
const DamageInspection = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <DamageinspectionContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default DamageInspection;