import { TailSpin } from 'react-loader-spinner'
const Loading = (props) => {
  return (
    <div className="loader" style={{
      position: "absolute",
      top: "45%",
      left: "45%",
      zIndex: 100,
      display: props.loadingDis
    }}>
      <TailSpin
        height="100"
        width="100"
        color='grey'
        ariaLabel='loading'
      />
    </div>
  );
}

export default Loading;