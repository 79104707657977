import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Row, Col, Table, Image, Button } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { staffPositions, staffPositionOrders } from "../config/config";
import QRcode from "../images/moshimoBotQRcode.png";
import EditStaffNameModal from "./modals/EditStaffNameModal";
import "../css/Member.css"

const MemberContent = ({ globalState }) => {
  const {
    officeName,
    staffList,
    lineUserList,
    isDataLoading
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const [staffName, setStaffName] = useState("");
  const [staffId, setStaffId] = useState("");

  const [mdlShow, setMdlShow] = useState(false);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getHomeInfo(params);
  }
  useEffect(() => {
    loadFunc();
  }, []);

  const handleDeleteStaff = (id) => {
    const order = Number(staffList[id].position_order);
    if (window.confirm(`${staffPositions[staffList[id].position]}${staffPositionOrders.substring(order, order + 1)}を削除してもよろしいですか？`)) {
      const appId = sessionStorage.getItem("appId");
      const appPass = sessionStorage.getItem("appPass");
      const viewOfficeId = sessionStorage.getItem("viewOfficeId") || "";
      const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
      const params = { appId: appId, appPass: appPass, staffId: id, officeId: officeId };
      globalState.deleteStaff(params);
      loadFunc();
    }
  }

  const handleEditStaffName = (id, name) => {
    setStaffId(id);
    setStaffName(name);
    setMdlShow(true)
  }
  const handleSaveStaffName = () => {
    setMdlShow(false);
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const viewOfficeId = sessionStorage.getItem("viewOfficeId") || "";
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, staffId: staffId, staffName: staffName, officeId: officeId };
    globalState.editStaffName(params);
  }

  const handleDeleteLineUser = (id, name) => {
    if (window.confirm(`${name}を削除してもよろしいですか？`)) {
      const appId = sessionStorage.getItem("appId");
      const appPass = sessionStorage.getItem("appPass");
      const viewOfficeId = sessionStorage.getItem("viewOfficeId") || "";
      const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
      const params = { appId: appId, appPass: appPass, staffId: id, officeId: officeId };
      globalState.deleteLineUser(params);
      loadFunc();
    }
  }

  const renderStaffList = (staffList) => {
    if (!staffList) return <></>;
    const staffIds = Object.keys(staffList);
    return staffIds
      .sort((a, b) => {
        if (staffList[a].position !== staffList[b].position) {
          return staffList[a].position < staffList[b].position ? -1 : 1;
        } else {
          return staffList[a].position_order < staffList[b].position_order ? -1 : 1;
        }
      })
      .map(id => {
        const order = Number(staffList[id].position_order);
        return (<tr key={`s${id}`}>
          <td>{staffPositions[staffList[id].position]}{staffPositionOrders.substring(order, order + 1)}</td>
          <td>{staffList[id].name || "未設定"}</td>
          <td><Button variant="primary" size="sm" onClick={() => handleEditStaffName(id, staffList[id].name)}>名前編集
          </Button></td>
          <td><Button variant="danger" size="sm" onClick={() => handleDeleteStaff(id)}>削除
          </Button></td>
        </tr>)
      })
  };

  const renderLineUserList = (lineUserList) => {
    if (!Object.keys(lineUserList).length) return <></>
    return Object.keys(lineUserList)
      .map(id => {
        return (<tr key={`lu${id}`}>
          <td>{lineUserList[id].user_name}</td>
          <td><Button variant="danger" size="sm" onClick={() => handleDeleteLineUser(id, lineUserList[id].user_name)}>削除
          </Button></td>
        </tr>)
      })
  };


  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 3, offset: 2 }}>
          <h5>登録メンバー情報</h5>
          <div className="staffTable">
            <Table size="sm">
              <tbody>
                {renderStaffList(staffList)}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col md={{ span: 3 }}>
          <h5>LINEメンバー情報</h5>
          <div className="staffTable">
            <Table size="sm" >
              <tbody>
                {renderLineUserList(lineUserList)}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col md={{ span: 3 }}>
          <h5>LINE QRコード</h5>
          <Image src={QRcode} width="100px" height="100px" />
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <EditStaffNameModal
        saveData={handleSaveStaffName}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
        setStaffName={setStaffName}
        staffName={staffName}
      />
    </Container>
  )
}
const Member = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <MemberContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Member;