import React from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";



const AddProductModal = (props) => {
  const { mdlShow, mdlSetHide, setData, data, saveData } =
    props;

  const handleSaveData = () => {
    if (data.name === "" || data.pcs === "" || data.barcode === "") {
      alert("必要な項目が記入または選択されていません");
      return false;
    }
    saveData();
  }

  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>新規商品登録</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="table" size="sm">
          <tbody>
            <tr>
              <th>商品名</th>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>価格</th>
              <td>
                <Form.Control
                  type="number"
                  size="sm"
                  value={data.price}
                  onChange={(e) => setData("price", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>入数</th>
              <td>
                <Form.Control
                  type="number"
                  size="sm"
                  value={data.pcs}
                  onChange={(e) => setData("pcs", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>バーコード</th>
              <td>
                <Form.Control
                  type="text"
                  size="sm"
                  value={data.barcode}
                  onChange={(e) => setData("barcode", e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => handleSaveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
