export const staffPositions = { 1: "施設長", 2: "主任　", 3: "担当　" };
export const staffPositionOrders = "ABCDEFGHIJKLMNOPQ";
export const safetyConfirmationDic = {
    isaaffected_and_injured: "被災（外傷あり）",
    affected_not_injured: "被災（外傷なし）",
    not_affected: "被災なし",
    in_office: "事業所内",
    at_home: "自宅",
    out_of_town: "外出先",
    can_come: "出社できる",
    cannot_come: "出社できない",
};
