import React from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";

const AddTrainingModal = (props) => {
  const { mdlShow, mdlSetHide, data, setData, saveData, officeName, diasterList } =
    props;

  const renderWarningOpt = (() => {
    const list = [];
    list.push(<option key="w0" value={0}>未設定</option>);
    Object.keys(diasterList).forEach(id => {
      list.push(<option key={`w${id}`} value={id}>{diasterList[id].name}</option>);
    })
    return list;
  })();
  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>訓練登録</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="table" size="sm">
          <tbody>
            <tr>
              <th>事業所名</th>
              <td>{officeName}</td>
            </tr>
            <tr>
              <th>訓練開始日時</th>
              <td>
                <Form.Control
                  type="date"
                  size="sm"
                  value={data.date}
                  onChange={(e) => setData("date", e.target.value)}
                />
                <Form.Control
                  type="time"
                  size="sm"
                  value={data.time}
                  onChange={(e) => setData("time", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>災害内容</th>
              <td>
                <Form.Select
                  as="select"
                  size="sm"
                  value={data.diasterId}
                  onChange={(e) => setData("diasterId", e.target.value)}
                >
                  {renderWarningOpt}
                </Form.Select>
              </td>
            </tr>
          </tbody>
        </Table>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => saveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTrainingModal;
