import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import './App2.css';
import './css/leaflet.css';

import Home from './components/Home';
import Member from './components/Member';
import Stockpile from './components/Stockpile';
import Training from './components/Training';
import EachTraining from './components/EachTraining';
import Bookmark from './components/Bookmark';
import Login from './components/Login';
import Manual from './components/Manual';
import Banner from './components/Banner';
import OfficeList from './components/OfficeList';
import ProductList from './components/ProductList';
import PrivacyPolycy from './components/PrivacyPolycy';
import About from './components/About';
import DamageAnnounceMap from './components/DamageAnnounceMap';
import PreventionTodoList from './components/PreventionTodoList';
import SafetyConfirmation from './components/SafetyConfirmation';
import DamageInspection from './components/DamageInspection';
import TrainingManagement from './components/TrainingManagement';
import DamageReportMap from './components/DamageReportMap';
import { Provider } from "unstated";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider>
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/member' element={<Member />} />
        <Route path='/stockpile' element={<Stockpile />} />
        <Route path='/training' element={<Training />} />
        <Route path='/eachtraining/:paramId' element={<EachTraining />} />
        <Route path='/bookmark' element={<Bookmark />} />
        <Route path='/manual' element={<Manual />} />
        <Route path='/banner' element={<Banner />} />
        <Route path='/officelist' element={<OfficeList />} />
        <Route path='/productlist' element={<ProductList />} />
        <Route path='/privacypolycy' element={<PrivacyPolycy />} />
        <Route path='/about' element={<About />} />
        <Route path='/damageAnnounceMap' element={<DamageAnnounceMap />} />
        <Route path='/preventionTodoList' element={<PreventionTodoList />} />
        <Route path='/safetyConfirmation' element={<SafetyConfirmation />} />
        <Route path='/damageInspection' element={<DamageInspection />} />
        <Route path='/trainingManagement' element={<TrainingManagement />} />
        <Route path='/damageReportMap' element={<DamageReportMap />} />
      </Routes>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

