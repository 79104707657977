import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";



const AddPreventionTodoModal = (props) => {
  const { mdlShow, mdlSetHide, setData, data, saveData, diasterList, positionList } =
    props;

  const handleSaveData = () => {
    if (data.content === "" || data.priority === "" || data.diasterIds.length === 0 || data.positionIds.length === 0) {
      alert("必要な項目が記入または選択されていません");
      return false;
    }
    saveData();
  }

  const handleSetDiaster = (did) => {
    const _diasterIds = [...data.diasterIds];
    const index = _diasterIds.indexOf(did);
    if (index === -1) {
      _diasterIds.push(did);
    } else {
      _diasterIds.splice(index, 1);
    }
    setData('diasterIds', _diasterIds);

  }
  const handleSetPosition = (pid) => {
    const _positionIds = [...data.positionIds];
    const index = _positionIds.indexOf(pid);
    if (index === -1) {
      _positionIds.push(pid);
    } else {
      _positionIds.splice(index, 1);
    }
    setData('positionIds', _positionIds);
  }

  return (
    <Modal show={mdlShow} onHide={mdlSetHide}>
      <Modal.Header closeButton>
        <Modal.Title>TODO登録</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="table" size="sm">
          <tbody>
            <tr>
              <th>内容</th>
              <td>
                <Form.Control
                  as="textarea"
                  size="sm"
                  value={data.content}
                  onChange={(e) => setData("content", e.target.value)}
                  rows={5}
                />
              </td>
            </tr>
            <tr>
              <th>優先度</th>
              <td>
                <Form.Control
                  type="number"
                  size="sm"
                  value={data.priority}
                  onChange={(e) => setData("priority", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>災害別</th>
              <td>
                {Object.keys(diasterList).map(did =>
                  <Form.Check
                    key={`dia${did}`}
                    type="checkbox"
                    label={diasterList[did].name}
                    checked={data.diasterIds.indexOf(Number(did)) !== -1}
                    onChange={() => handleSetDiaster(Number(did))}
                  />)}
              </td>
            </tr>
            <tr>
              <th>役職別</th>
              <td>
                {Object.keys(positionList).map(pid =>
                  <Form.Check
                    key={`pos${pid}`}
                    type="checkbox"
                    label={positionList[pid].name}
                    checked={data.positionIds.indexOf(Number(pid)) !== -1}
                    onChange={() => handleSetPosition(Number(pid))}
                  />)}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => mdlSetHide()}>
          キャンセル
        </Button>
        <Button variant="primary" onClick={() => handleSaveData()}>
          登　録
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPreventionTodoModal;
