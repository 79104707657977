import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";
import ComNav from "./ComNav";
import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { PlusLg, PencilFill } from 'react-bootstrap-icons';
import moment from "moment";
import AddStockpileModal from "./modals/AddStockpileModal";

const StockpileContent = ({ globalState }) => {
  const {
    officeName,
    stockpileList,
    isDataLoading
  } = globalState.state;

  const admin = sessionStorage.getItem("admin");

  const navigate = useNavigate();
  const initData = {
    stockpileId: "",
    name: "",
    amount: 0,
    expiry_date: moment().format('YYYY-MM-DD'),
  }
  const [data, setData] = useState(initData);
  const [mdlShow, setMdlShow] = useState(false);
  const [label, setLabel] = useState("new");

  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const viewOfficeId = sessionStorage.getItem("viewOfficeId");
    if (Number(admin) === 1 && (!viewOfficeId || Number(viewOfficeId) === 0)) {
      navigate("/OfficeList");
    }


    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = Number(admin) === 0 ? sessionStorage.getItem("officeId") : viewOfficeId;
    const params = { appId: appId, appPass: appPass, officeId: officeId };
    globalState.getStockpileInfo(params);
  }

  useEffect(() => {
    loadFunc();
  }, []);

  const handleAddStockpile = () => {
    setData(initData);
    setLabel("new");
    setMdlShow(true);
  }

  const handleEditStockpile = (v) => {
    let sd = { stockpileId: v.id, name: v.name, amount: v.amount, expiry_date: v.expiry_date };
    setData(sd);
    setLabel("edit");
    setMdlShow(true);

  }

  const handleSetData = (key, value) => {
    let sd = { ...data };
    sd[key] = value;
    setData(sd);
  }


  const handleDelConfirm = () => {
    if (window.confirm("このデータを削除してもよろしいですか？")) {
      deleteData(data.id);
    }
  }

  const handleDataCheck = () => {
    if (data.name === "") {
      alert("品目名が入力されていません");
      return false;
    }
    if (data.amount === 0) {
      alert("数量が入力されていません");
      return false;
    }
    if (data.expiry_date <= moment().format('YYYY-MM-DD')) {
      alert("賞味期限は、今日以降の日付に設定してください");
      return false;
    }
    saveData(data);
  }

  const saveData = () => {
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }

    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = sessionStorage.getItem("officeId");
    const params = { appId: appId, appPass: appPass, officeId: officeId, ...data };
    globalState.addStockpile(params);
  }

  const deleteData = () => {
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const officeId = sessionStorage.getItem("officeId");

    const params = { appId: appId, appPass: appPass, officeId: officeId, stockpileId: data.stockpileId };
    globalState.deleteStockpile(params);
  }

  const renderStockpileList =
    stockpileList.map(
      (v, index) => {
        return (
          <tr key={`sp${index}`}>
            <td>{v.name.substring(0, 10)}</td>
            <td>{v.amount}</td>
            <td></td>
            <td></td>
            <td>{v.expiry_date}</td>
            <td>
              <Button variant="primary" size="sm" onClick={() => handleEditStockpile(v)}>
                <PencilFill size={24} color="#FFF" />
              </Button>
            </td>
          </tr>
        )
      });
  return (
    <Container fluid >
      {Number(admin) === 1 ? <AdminNav /> : <></>}
      <ComNav officeName={officeName} admin={admin} loadFunc={loadFunc} />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 8, offset: 2 }}>
          <h5>
            備蓄品情報
            <Button variant="primary" size="sm" style={{ marginLeft: "20px" }} onClick={() => handleAddStockpile()}>
              <PlusLg size={24} color="#FFF" />
            </Button>
          </h5>

          <Table striped size="sm">
            <thead>
              <tr>
                <th>品目</th>
                <th>登録量</th>
                <th>使用量</th>
                <th>在庫量</th>
                <th>賞味期限</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {renderStockpileList}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <AddStockpileModal
        saveData={handleDataCheck}
        deleteData={handleDelConfirm}
        setData={handleSetData}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
        data={data}
        label={label}
      />
    </Container>
  )
}
const Stockpile = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <StockpileContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default Stockpile;