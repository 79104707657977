import { useNavigate, useLocation } from "react-router-dom";
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import { BoxArrowLeft, ArrowClockwise } from 'react-bootstrap-icons';

const ComNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeKey = location.pathname.split("/")[1];
  const handleLogOut = () => {
    sessionStorage.clear();
    navigate("/Login");
  }

  return (
    <Navbar bg="light" variant="light">
      <Container>
        {Number(props.admin) === 0 ?
          <Navbar.Brand href="">
            <img
              alt=""
              src="./AppIcon.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            MOSHIMO
          </Navbar.Brand> : <></>}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto my-2 my-lg-0"
            activeKey={`/${activeKey}`}
            onSelect={(selectedKey) => navigate(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link href="/home">ホーム</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/member">メンバー</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/stockpile">備蓄品</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/training">訓練</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/bookmark">ブックマーク</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/manual">マニュアル</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/safetyConfirmation">安否確認</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/damageInspection">被災点検</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/damageReportMap">被災報告</Nav.Link>
            </Nav.Item>
          </Nav>
          {Number(props.admin) === 0 ?
            <div className="form-inline d-flex" >
              <Button
                className="reload"
                variant="primary"
                size="sm"
                onClick={() => { if (props.loadFunc) { props.loadFunc() } }}
              >
                <ArrowClockwise size={24} color="#FFF" />
              </Button>
              <Button variant="primary" size="sm" onClick={() => handleLogOut()}>
                <span style={{ marginRight: "10px", fontSize: "16px", verticalAlign: "middle" }}> {props.officeName}</span>
                <BoxArrowLeft size={24} color="#FFF" />
              </Button>
            </div> : <Button variant="primary" size="sm">
              <span style={{ marginRight: "10px", fontSize: "16px", verticalAlign: "middle" }}> {props.officeName}</span>
            </Button>}
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
}

export default ComNav;