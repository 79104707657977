import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Subscribe } from "unstated";
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import DataContainer from "../containers/DataContainer";

import AdminNav from "./AdminNav";
import Loading from "./Loading";
import { PlusLg, PencilFill } from 'react-bootstrap-icons';
import AddPreventionTodoModal from "./modals/AddPreventionTodoModal";

const PreventionTodoListContent = ({ globalState }) => {
  const {
    diasterList,
    positionList,
    todoList,
    isDataLoading,
  } = globalState.state;
  const admin = sessionStorage.getItem("admin");
  const navigate = useNavigate();
  const initData = {
    content: "",
    priority: 1,
    diasterIds: [],
    positionIds: [],
  };
  const [data, setData] = useState(initData);
  const [mdlShow, setMdlShow] = useState(false);
  const loadFunc = () => {
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    if (!admin || Number(admin) === 0) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");

    const params = { appId: appId, appPass: appPass };
    globalState.getPreventionTodoList(params);

  }
  useEffect(() => {
    loadFunc();
  }, []);

  const handleEditTodo = (v) => {
    setData({ ...v });
    setMdlShow(true);
  }

  const saveData = () => {
    setMdlShow(false);
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, ...data, admin: 1 };
    globalState.addPreventionTodo(params).then(rs => {
      if (rs) {
        alert('登録しました。');
      } else {
        alert('登録できませんでした。');
      }
      loadFunc();
    });;
  }

  const handleDeleteTodo = (id) => {
    if (!window.confirm("このTODOしてもよろしいですか？")) return false;
    if (!sessionStorage.getItem("appId") || !sessionStorage.getItem("appPass") || !sessionStorage.getItem("officeId")) {
      navigate("/Login");
    }
    const appId = sessionStorage.getItem("appId");
    const appPass = sessionStorage.getItem("appPass");
    const params = { appId: appId, appPass: appPass, id: id };
    globalState.deletePreventionTodo(params).then(rs => {
      if (rs) {
        alert('削除しました。');
      } else {
        alert('削除できませんでした。');
      }
      loadFunc();
    });;
  }


  const handleSetData = (key, value) => {
    let sd = { ...data };
    sd[key] = value;
    setData(sd);
  }

  const renderPreventionTodoList = (() => {
    const list = [];
    Object.keys(todoList).forEach(tid => {
      const v = todoList[tid];
      list.push(
        <tr key={`pt${tid}`}>
          <td>{v.content}</td>
          <td>{v.priority}</td>
          <td>{v.diasterIds.sort().map(did => <div key={`${tid}_${did}`} className="diasterLabel">{diasterList[did].name}</div>)}</td>
          <td>{v.positionIds.sort().map(pid => <div key={`${tid}_${pid}`} className="diasterLabel">{positionList[pid].name}</div>)}</td>
          <td>
            <Button variant="danger" size="sm" onClick={() => handleDeleteTodo(v.id)} >
              削除
            </Button>
          </td>
          <td>
            <Button variant="primary" size="sm" onClick={() => handleEditTodo(v)}>
              <PencilFill size={24} color="#FFF" />
            </Button>
          </td>
        </tr>
      )
    })
    return list;
  })();
  return (
    <Container fluid >
      <AdminNav />
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 9, offset: 1 }}>
          <h5>
            防災TODO
            <Button variant="primary" size="sm" style={{ marginLeft: "20px" }} onClick={() => handleEditTodo(initData)}>
              <PlusLg size={24} color="#FFF" />
            </Button>
          </h5>
          <div style={{ overflowY: "scroll", maxHeight: "600px" }}>
            <Table>
              <thead>
                <tr><th>内容</th><th>優先度</th><th>災害別</th><th>役職別</th><th></th><th></th></tr>
              </thead>
              <tbody>
                {renderPreventionTodoList}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Loading loadingDis={isDataLoading ? "block" : "none"} />
      <AddPreventionTodoModal
        saveData={saveData}
        setData={handleSetData}
        data={data}
        mdlShow={mdlShow}
        mdlSetHide={() => setMdlShow(false)}
        diasterList={diasterList}
        positionList={positionList}
      />
    </Container>
  )
}
const PreventionTodoList = () => {
  return (
    <Subscribe to={[DataContainer]}>
      {(globalState) => (
        <PreventionTodoListContent globalState={globalState} />
      )}
    </Subscribe>
  );
};

export default PreventionTodoList;